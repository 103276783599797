import { Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { pvrTheme, inoxTheme } from "./theme/themes";
import "primereact/resources/themes/lara-light-indigo/theme.css"; //theme
import "primereact/resources/primereact.min.css"; //core css
import "primeicons/primeicons.css"; //icons
import "bootstrap/dist/css/bootstrap.min.css";
import "./custom-style/style.css"; // == global styling
import "./custom-style/queries.css"; // == global styling
import "./custom-style/mobilequeries.css"; // == global styling
import { cinemaChain } from "./constants";
import { HelmetProvider, Helmet } from "react-helmet-async";
import ReactGA from "react-ga";

const React = require("react");

// ==== mobile views pages start rountes ==========
const Msearch = React.lazy(() =>
  import("./views/mobile-views/mobile-search/MSearch")
);
const MobileMovieDetails = React.lazy(() =>
  import("./views/mobile-views/mobile-movie-details/MobileMovieDeatils")
);
const MobilePersonDetails = React.lazy(() =>
  import("./views/mobile-views/mobile-person-details/MobilePersonDetails")
);
const MobileExperience = React.lazy(() =>
  import("./views/mobile-views/mobile-experience/MobileExperience")
);
const MobileCinemas = React.lazy(() =>
  import("./views/mobile-views/mobile-cinemas/MobileCinemas")
);
const MobileMap = React.lazy(() =>
  import("./views/mobile-views/mobile-map/MobileMap")
);
const InstaFood = React.lazy(() =>
  import("./views/mobile-views/instafood/InstaFood")
);
const MobilePayment = React.lazy(() =>
  import("./views/mobile-views/mobile-payment/MobilePayment")
);
const MobileGiftCard = React.lazy(() =>
  import("./views/mobile-views/mobile-gift-cards/MobileGiftCard")
);
const MobileProfile = React.lazy(() =>
  import("./views/mobile-views/mobile-profile/MobileProfile")
);
const MobileTicketSummary = React.lazy(() =>
  import(
    "./views/mobile-views/mobile-profile/mobile-profile-pages/ProfileBookingDetails"
  )
);
const MobileQRFound = React.lazy(() =>
  import("./views/mobile-views/mobile-qr-found/MobileQrFound")
);

const MobileComingSoon = React.lazy(() =>
  import("./views/mobile-views/mobile-coming-soon/MobileComingSoon")
);

const MobileTrailer = React.lazy(() =>
  import("./views/mobile-trailer/MobileTrailer")
);

// ==== mobile views pages end rountes ============

//Dm4 page

const DMFOUR = React.lazy(() => import("./views/dm4/DMFOUR"));
const DMFOURSTEP2 = React.lazy(() => import("./views/dm4/DMFOURStep2"));
const DMFOURSTEP3 = React.lazy(() => import("./views/dm4/DMFOURStep3"));

const Home = React.lazy(() => import("./views/home/Home"));
const ShowTime = React.lazy(() => import("./views/show-time/ShowTime"));
const SeatLayout = React.lazy(() => import("./views/seat-layout/SeatLayout"));
const SeatLayoutWchat = React.lazy(() =>
  import("./views/seat-layout/SeatLayoutWchat")
);
const GetFood = React.lazy(() => import("./views/get-food/GetFood"));
const CinemasSession = React.lazy(() =>
  import("./views/cinemas/cinemaList/CinemasSession")
);
const ExperienceDetails = React.lazy(() =>
  import("./views/cinemas/experiences/ExperienceDetails")
);
const Experiences = React.lazy(() =>
  import("./views/cinemas/experiences/Experiences")
);
const PAYMENT = React.lazy(() => import("./views/payment/Payment"));
const OldQrCODE = React.lazy(() => import("./views/oldQrFood/OldQrFood"));
const MovieSession = React.lazy(() =>
  import("./views/movie-sessions/MovieSessions")
);
const Cinemas = React.lazy(() => import("./views/cinemas/Cinemas"));
const Offers = React.lazy(() => import("./views/offers/Offers"));
const Passport = React.lazy(() => import("./views/passport/Home"));
const OffersDetail = React.lazy(() => import("./views/offers/OffersDetail"));

//const Passport = React.lazy(() => import("./views/passport/Pasport"));
const ComingSoon = React.lazy(() => import("./views/coming-soon/ComingSoon"));
const Giftcards = React.lazy(() => import("./views/gift-cards/GiftCards"));
const BulkGiftcards = React.lazy(() =>
  import("./views/gift-cards/BulkPurchage")
);
const KotakPPplus = React.lazy(() =>
  import("./views/kotak-pp-plus/KotakPPPlus")
);
const MovieOnDemands = React.lazy(() =>
  import("./views/movie-on-demand/MovieOnDemand")
);

// ==== static page toutings =========
const CoroporateBooking = React.lazy(() =>
  import("./views/bulk-booking/coroporate-booking/CoroporateBooking")
);
const Advertise = React.lazy(() =>
  import("./views/static-pages/advertise/Advertise")
);
const AboutUs = React.lazy(() =>
  import("./views/static-pages/about-us/AboutUs")
);
const VPristine = React.lazy(() =>
  import("./views/static-pages/v-pristine/VPristine")
);
const FeedbackPage = React.lazy(() =>
  import("./views/static-pages/feedback/Feedback")
);
const Birthday = React.lazy(() =>
  import("./views/static-pages/birthday-request/Birthday")
);
const Oscar = React.lazy(() => import("./views/oscar/Oscar"));
const Faq = React.lazy(() => import("./views/static-pages/faq/Faq"));
const News = React.lazy(() => import("./views/static-pages/news/News"));
const BeyondMovie = React.lazy(() =>
  import("./views/static-pages/beyond-movies/BeyondMovie")
);
const PrivacyPolicy = React.lazy(() =>
  import("./views/static-pages/privacy-policy/PrivacyPolicy")
);
const Termsconditions = React.lazy(() =>
  import("./views/static-pages/terms-conditions/TermsConditions")
);
const Termsuse = React.lazy(() =>
  import("./views/static-pages/terms-use/Termsuse")
);
const DonationTnc = React.lazy(() =>
  import("./views/static-pages/terms-use/Donation")
);
const Careers = React.lazy(() =>
  import("./views/static-pages/careers-us/Careers")
);

const InvestorSections = React.lazy(() =>
  import("./views/static-pages/investers-section/Investors")
);

const MovileLibrary = React.lazy(() =>
  import("./views/static-pages/movie-library/MovileLibrary")
);

const CinemaFormat = React.lazy(() =>
  import("./views/static-pages/cinema-format/CinemaFormat")
);

const Profile = React.lazy(() => import("./views/profile/Profile"));

const Ticket = React.lazy(() => import("./views/final-ticket/FinalTicket"));
const PassportTicket = React.lazy(() =>
  import("./views/final-ticket/PassportTicket")
);
const GiftCardTicket = React.lazy(() =>
  import("./views/final-ticket/GiftCardTicket")
);
const FoodTicket = React.lazy(() => import("./views/final-ticket/FoodTicket"));

const EarlyAccess = React.lazy(() =>
  import("./views/early-eccess/EarlyAccess")
);
const FilmFestival = React.lazy(() => import("./views/film-festival/Festival"));
const Audio = React.lazy(() => import("./views/static-pages/audio/Audio"));

const SetAlertMovie = React.lazy(() =>
  import("./views/set-alert-movie/SetAlertMovie")
);

const UnpaidTopaid = React.lazy(() =>
  import("./views/unpaid-to-paid/UnpaidToPaid")
);

const PageNotFound = React.lazy(() =>
  import("./views/page-not-found/PageNotFound")
);

function App() {
  const themeVal = cinemaChain === "PVR" ? "pvrTheme" : "inoxTheme";

  if (typeof localStorage !== "undefined") {
    // Store the theme value in localStorage
    localStorage.setItem("theme", themeVal);
  } else {
    // Handle the case where localStorage is not available
    console.error("localStorage is not available");
  }

  const theme = cinemaChain === "PVR" ? pvrTheme : inoxTheme;
  const isMobile =
    typeof window !== "undefined" &&
    window.matchMedia("(max-width: 575px)").matches;
  if (cinemaChain === "PVR") ReactGA.initialize("G-CPXWTKDXV8");
  else ReactGA.initialize("G-FYEYSCFLNJ");

  const loading = (
    <div
      className="pt-3 d-flex justify-content-center align-items-center"
      style={{ height: "100vh", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
    >
      <div>
        <i
          className="pi pi-spin pi-spinner"
          style={{
            fontSize: "5em",
            color: cinemaChain === "PVR" ? "#FFCB05" : "#234B9E",
          }}
        ></i>
      </div>
    </div>
  );

  // if (!localStorage.getItem("lat")) {
  //   localStorage.setItem("lat", "0.000");
  //   localStorage.setItem("long", "0.000");
  // }

  // Check if localStorage is available
  if (typeof localStorage !== "undefined") {
    // Check if the 'lat' key is not already set
    if (!localStorage.getItem("lat")) {
      // Set the 'lat' and 'long' keys to default values
      localStorage.setItem("lat", "0.000");
      localStorage.setItem("long", "0.000");
    }
  } else {
    // Handle the case where localStorage is not available
    console.error("localStorage is not available");
  }
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet>
          <title>{cinemaChain === "PVR" ? "PVR Cinemas" : "INOX Movies"}</title>
          <link
            rel="icon"
            href={cinemaChain === "PVR" ? "/pvr.ico" : "/inox.ico"}
          />
          <meta name="description" content="PVR INOX" />
        </Helmet>
        <ThemeProvider theme={theme}>
          <Suspense fallback={loading}>
            <Router>
              <Routes>
                <Route path="/" name="Home" element={<Home />} />
                <Route path="/home" name="Home" element={<Home />} />
                <Route
                  path="/playtrailer/:moviename"
                  name="Home"
                  element={<Home />}
                />
                <Route path="/dm-four" name="DMfour" element={<DMFOUR />} />
                <Route
                  path="/dm-fours2"
                  name="DMfourS2"
                  element={<DMFOURSTEP2 />}
                />
                <Route
                  path="/dm-fours3"
                  name="DMfourS3"
                  element={<DMFOURSTEP3 />}
                />

                <Route
                  path="/show-timings"
                  name="Show Time"
                  element={<ShowTime />}
                />
                <Route
                  path="/passport"
                  name="Passport"
                  element={<Passport />}
                />
                <Route path="/PSPT" name="Passport" element={<Passport />} />
                <Route
                  path="/moviesessions/:city/:moviename/:mid"
                  name="Movies Session"
                  element={<MovieSession />}
                />
                <Route
                  path="/moviesessions/:moviename/:mid"
                  name="Movies Session"
                  element={<MovieSession />}
                />
                <Route
                  path="/cinemas/:city"
                  name="Cinemas"
                  element={isMobile ? <MobileCinemas /> : <Cinemas />}
                />
                <Route
                  path="/mobile-map"
                  name="MobileMap"
                  element={<MobileMap />}
                />

                <Route
                  path="/insta-food"
                  name="InstaFood"
                  element={<InstaFood />}
                />
                <Route
                  path="/getqrcode/:cinemacode"
                  name="QR Food"
                  element={<OldQrCODE />}
                />
                <Route
                  path="/booking/:cinemacode"
                  name="QR Food"
                  element={<OldQrCODE />}
                />
                <Route
                  path="/qrcode/:cityName/:theaterId"
                  name="QR Food"
                  element={<MobileQRFound />}
                />
                <Route
                  path="/experience/:city"
                  name="Experience"
                  element={<Experiences />}
                />
                <Route
                  path="/experience"
                  name="Cinemas"
                  element={<Experiences />}
                />
                <Route
                  path="/experience/:city/:format"
                  name="Cinemas"
                  element={<ExperienceDetails />}
                />
                <Route
                  path="/experiencesessions/:format"
                  name="Cinemas"
                  element={<ExperienceDetails />}
                />
                <Route
                  path="/cinemasessions/:city/:cname/:ccode"
                  name="Cinemas"
                  element={<CinemasSession />}
                />
                <Route
                  path="/cinemasessions/:cname/:ccode"
                  name="Cinemas"
                  element={<CinemasSession />}
                />
                <Route path="/offers" name="Offers" element={<Offers />} />
                <Route
                  path="/offers/offersdetails/:id/:name"
                  name="Offers"
                  element={<OffersDetail />}
                />

                <Route
                  path="/coming-soon"
                  name="Coming Soon"
                  element={isMobile ? <MobileComingSoon /> : <ComingSoon />}
                />
                <Route
                  path="/coming-soon-movie-calender"
                  name="Coming Soon Movie CCalender"
                  element={<ComingSoon showwebheader={false} />}
                />
                <Route
                  path="/comingsoon/:moviename/:mid"
                  name="Coming Soon"
                  element={<ComingSoon />}
                />
                <Route
                  path="/comingsoon/:mid"
                  name="Coming Soon"
                  element={<ComingSoon />}
                />
                <Route
                  path="/seatlayout/:encrypted_id"
                  name="Seat Layout"
                  element={<SeatLayout />}
                />
                <Route
                  path="/seatlayout-wchat/:encrypted_id"
                  name="Seat Layout"
                  element={<SeatLayoutWchat />}
                />
                <Route
                  path="/food/:city/:cinemaname/:cinemaId"
                  element={<GetFood />}
                ></Route>
                <Route
                  path="/payment/:cinemaname"
                  element={<PAYMENT />}
                ></Route>
                <Route
                  path="/gift-cards"
                  name="Gift Card"
                  element={isMobile ? <MobileGiftCard /> : <Giftcards />}
                ></Route>
                <Route
                  path="/bulk-gift-cards"
                  name="Bulk Gift Card"
                  element={<BulkGiftcards />}
                ></Route>
                <Route
                  path="/kotak-pp-plus"
                  name="Kotak PP+"
                  element={<KotakPPplus />}
                ></Route>
                <Route
                  path="/movie-on-demand"
                  name="Movies On Demand"
                  element={<MovieOnDemands />}
                ></Route>
                {/* static pages  */}
                <Route
                  path="/bulkbooking/coroporate-booking"
                  name="Coroporate Booking"
                  element={<CoroporateBooking />}
                ></Route>
                <Route
                  path="/advertise"
                  name="Advertise"
                  element={<Advertise />}
                ></Route>
                <Route
                  path="/about-us"
                  name="About Us"
                  element={<AboutUs />}
                ></Route>
                <Route
                  path="/v-pristine"
                  name="V Pristine"
                  element={<VPristine />}
                ></Route>
                <Route
                  path="/feedback"
                  name="Feedback"
                  element={<FeedbackPage />}
                ></Route>
                <Route
                  path="/birthday-request"
                  name="birthday-request"
                  element={<Birthday />}
                ></Route>
                <Route path="/faq" name="faq" element={<Faq />}></Route>
                <Route
                  path="/campaign/:oscar"
                  name="Oscar"
                  element={<Oscar />}
                ></Route>
                <Route path="/news" name="news" element={<News />}></Route>
                <Route
                  path="/giftticket"
                  name="giftticket"
                  element={<GiftCardTicket />}
                ></Route>
                <Route
                  path="/beyond-movies"
                  name="beyond-movies"
                  element={<BeyondMovie />}
                ></Route>
                <Route
                  path="/privacy-policy"
                  name="privacy-policy"
                  element={<PrivacyPolicy />}
                ></Route>
                <Route
                  path="/terms-conditions/:tabName"
                  name="terms-conditions"
                  element={<Termsconditions />}
                ></Route>
                <Route
                  path="/terms-use"
                  name="terms-use"
                  element={<Termsuse />}
                ></Route>
                <Route
                  path="/donation-tnc"
                  name="terms-use"
                  element={<DonationTnc />}
                ></Route>
                <Route
                  path="/careers-us"
                  name="careers-us"
                  element={<Careers />}
                ></Route>
                <Route
                  path="/investors-section"
                  name="Investors"
                  element={<InvestorSections />}
                ></Route>
                <Route
                  path="/movie-library"
                  name="MoveLibrary"
                  element={<MovileLibrary />}
                ></Route>
                <Route
                  path="/cinema-format"
                  name="CinemaFormat"
                  element={<CinemaFormat />}
                ></Route>
                <Route path="/profile" name="Profile" element={<Profile />} />
                <Route
                  path="/personaldetails"
                  name="Profile"
                  element={<Profile />}
                />

                <Route
                  path="/mybooking"
                  name="Profile"
                  element={<Profile viewmode="1" />}
                />

                <Route
                  path="/activegiftcard"
                  name="Profile"
                  element={<Profile viewmode="1" gc="1" />}
                />
                <Route
                  path="/myticketgc"
                  name="Profile"
                  element={<Profile viewmode="1" gc="1" />}
                />
                <Route
                  path="/moviealertlist"
                  name="Profile"
                  element={<Profile viewmode="2" />}
                />

                <Route
                  path="/booking-ticket"
                  name="Movie Ticket"
                  element={<Ticket />}
                />
                <Route
                  path="/passport-ticket"
                  name="Passport Ticket"
                  element={<PassportTicket />}
                />

                <Route
                  path="/giftcard-ticket"
                  name="Gift Card Ticket"
                  element={<GiftCardTicket />}
                />
                <Route
                  path="/food-ticket"
                  name="Food Ticket"
                  element={<FoodTicket />}
                />
                <Route
                  path="/m.trailer"
                  name="Trailer&Music"
                  element={<MobileTrailer />}
                />
                <Route
                  path="/early-access/:movieId"
                  name="Early Access"
                  element={<EarlyAccess />}
                />
                <Route
                  path="/live-movies"
                  name="Film Festival"
                  element={<FilmFestival />}
                />
                <Route
                  path="/audio/:var1?/:var2?/:var3?/:var4?/:var5?/:var6?/:var7?/:var8?/:var9?/:var10?/:var11?/"
                  name="AUDIO"
                  element={<Audio />}
                />
                <Route
                  path="/setalert/:movieCode"
                  name="Set Alert"
                  element={<SetAlertMovie />}
                />
                {/* mobile site router start  */}
                <Route path="/m.search" name="Search" element={<Msearch />} />
                <Route
                  path="/m.movie-details/:movieId/:movieType"
                  name="Movie Details"
                  element={<MobileMovieDetails />}
                />
                <Route
                  path="/m.movie-details/person-details/:pId/:pType"
                  name="Movie Details"
                  element={<MobilePersonDetails />}
                />
                <Route
                  path="/m.experience/:city/:exp"
                  name="Experience"
                  element={<MobileExperience />}
                />
                <Route
                  path="/m.payment/:cinemaname"
                  name="Payment"
                  element={<MobilePayment />}
                />
                <Route
                  path="/m.profile"
                  name="Profile"
                  element={<MobileProfile />}
                />
                <Route
                  path="/m.booking-detail/:bookType/:bookingId/:theaterId"
                  name="Booking Details"
                  element={<MobileTicketSummary />}
                />
                {/* mobile site router end MobileTicketSummary
                 */}
                <Route
                  path="/unpaid-to-paid/:theaterId/:bookingId"
                  name="Unpaid to Paid"
                  element={<UnpaidTopaid />}
                />
                <Route
                  path="*"
                  name="Page not found"
                  element={<PageNotFound />}
                />
              </Routes>
            </Router>
          </Suspense>
        </ThemeProvider>
      </HelmetProvider>
    </React.Fragment>
  );
}

export default App;
